/* eslint-disable react/prop-types */
// importSource //

import React, { useEffect, useState } from "react";
import tw, { css, theme } from "twin.macro";
import { useAuth, useTheme, useWindowDimensions } from "~hooks";
import { ArticleCardSmall, Button, Go, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";
import { getColor } from "~utils/helpers";

import { ReactComponent as Token } from "~assets/icons/token.svg";
import { ReactComponent as UIArticle } from "~assets/icons/ui-article.svg";
import { ReactComponent as UIAudio } from "~assets/icons/ui-audio.svg";
import { ReactComponent as UIDownload } from "~assets/icons/ui-downloads.svg";
import { ReactComponent as UIVideo } from "~assets/icons/ui-video.svg";
import { ReactComponent as UIWebinar } from "~assets/icons/ui-webinar.svg";

const ArticleCard = ({ article, full = false, withLink = false }) => {
  // --------------------------------------------------------------------------
  // context / ref / state

  const { account, loggedIn } = useAuth();
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  // --------------------------------------------------------------------------
  // variables

  const isEvent = article?.type?.title === `event`;

  const { availability, tokenKey, type } = article;

  let { cost } = article;

  const url = `/${isEvent ? `events` : `library`}/${article.slug.current}`;

  const [isAvailable, setIsAvailable] = useState(null);

  if (typeof cost === `undefined` || cost === ``) {
    cost = 0;
  }

  useEffect(() => {
    if (!account || !withLink) {
      setIsAvailable(availability === -1);
    }

    if (isEvent) {
      setIsAvailable(true);
    } else {
      switch (availability) {
        case -1:
          // it's free
          setIsAvailable(true);
          break;

        case 0:
          // members only
          setIsAvailable(loggedIn);
          break;

        case 1: {
          // premium
          const parsedKeys = account?.purchasedArticles?.split(`,`);

          setIsAvailable(
            parsedKeys?.includes(article?.tokenKey?.toLowerCase())
          );

          break;
        }

        default:
          break;
      }
    }
  }, [account, loggedIn]);

  // --------------------------------------------------------------------------
  // render

  let background = lowImpactMode
    ? colours.background
    : article.theme.backgroundColour.hex;
  let foreground = lowImpactMode
    ? colours.foreground
    : article.theme.foregroundColour.hex;

  if (full) {
    background = lowImpactMode
      ? colours.background
      : article.theme.backgroundColour.hex;
    foreground = lowImpactMode
      ? colours.foreground
      : article.theme.foregroundColour.hex;
  }

  let icon;

  switch (article?.type?.title?.toLowerCase()) {
    case `interview`:
      // icon = <UIAudio css={[tw`w-1/2`]} fill={foreground} />;
      icon = <UIArticle css={[tw`w-1/2`]} fill={foreground} />;
      break;

    case `download`:
      icon = <UIDownload css={[tw`w-1/2`]} fill={foreground} />;
      break;

    case `content`:
      icon = <UIVideo css={[tw`w-1/2`]} fill={foreground} />;
      break;

    case `event`:
      icon = <UIWebinar css={[tw`w-1/2`]} fill={foreground} />;
      break;

    case `article`:
    default:
      icon = <UIArticle css={[tw`w-1/2`]} fill={foreground} />;
      break;
  }

  return (
    <article
      css={[
        css`
          ${colourTransitionCSS};

          border-radius: ${full ? `0` : `15px`};
          background: ${background};
          color: ${foreground};
        `,
        full
          ? tw`w-full relative md:flex md:items-stretch md:justify-center overflow-hidden`
          : tw`w-full relative mt-12 mb-12 md:flex md:items-stretch md:justify-center overflow-hidden`
      ]}
    >
      <div css={[tw`w-full md:w-1/2 relative block`]}>
        <figure
          css={[
            css`
              ${full
                ? `padding-bottom: 100%;`
                : `height: 100%; min-height: 40vw;`};
            `,
            tw`w-full relative block`
          ]}
        >
          <div
            css={[
              tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center border-r`
            ]}
          >
            {(article?.cardImage && (
              <figure
                css={[
                  css`
                    .gatsby-image-wrapper,
                    img {
                      max-width: none !important;
                      max-height: none !important;
                    }
                  `,
                  tw`w-full h-full relative flex items-center justify-center`
                ]}
              >
                <Image
                  _css={[
                    css`
                      width: 100%;
                      height: 100%;
                      position: relative;
                      display: block;
                      object-fit: cover;
                    `
                  ]}
                  image={article.cardImage}
                />
              </figure>
            )) || (
              <h3 css={[tw`font-head text-h3 md:text-h3-md`]}>
                Image goes here
              </h3>
            )}
          </div>
        </figure>
      </div>

      <div
        css={[
          css`
            flex: 1 1;

            ${full ? `padding-top: 1.5rem; padding-bottom: 3rem;` : ``}
            padding-left: 0.75rem;
            padding-right: 0.75rem;

            ${MEDIA_QUERIES.desktop} {
              ${full && `padding-top: 7vw;`};
              padding-left: 2rem;
              padding-right: 2rem;
            }
          `,
          full
            ? tw`w-full md:w-1/2 relative flex flex-col items-stretch`
            : tw`w-full md:w-1/2 relative flex flex-col items-stretch py-10`
        ]}
      >
        <header
          css={[
            tw`w-full relative flex items-center justify-between pb-4 border-b`
          ]}
        >
          <div css={[tw`relative flex items-center`]}>
            <div
              css={[
                css`
                  //
                `,
                tw`w-6 h-6 relative flex items-center justify-center mr-2 rounded-full border`
              ]}
            >
              {icon}
            </div>

            <h3
              css={[
                css`
                  margin-bottom: -0.15vw;
                `,
                tw`font-head text-b2 md:text-b2-md uppercase`
              ]}
            >
              {type?.title}
            </h3>
          </div>

          {typeof article?.readTime === `string` && article.readTime !== `` && (
            <h4 css={[tw`font-head text-b2 md:text-b2-md italic`]}>
              {article?.readTime}
            </h4>
          )}
        </header>

        <div
          css={[
            css`
              flex: 1 1;
            `,
            tw`w-full relative block pt-6`
          ]}
        >
          <h2 css={[tw`font-head text-h3 md:text-h3-md`]}>{article?.title}</h2>

          <p css={[tw`mt-4 font-body text-b2 md:text-b2-md`]}>
            {article?.excerpt}
          </p>

          {withLink && (
            <>
              <Go to={url}>
                {(isAvailable && (
                  <Button
                    _css={[tw`mt-5 px-4`]}
                    color={article?.theme?.buttonColour || `black`}
                    noborder
                    text="Learn more"
                  />
                )) || (
                  <>
                    <button
                      type="button"
                      css={[
                        css`
                          transition: 0.3s ease opacity;

                          background: ${article?.theme?.buttonColour ||
                          getColor(`black`)};
                          color: ${article?.theme?.buttonColour ===
                          getColor(`charcoal`)
                            ? getColor(`wheat`)
                            : getColor(`black`)};

                          svg {
                            fill: ${article?.theme?.buttonColour ===
                            getColor(`charcoal`)
                              ? getColor(`wheat`)
                              : getColor(`black`)};
                          }

                          ${MEDIA_QUERIES.hoverable} {
                            &:hover {
                              opacity: 0.4;
                            }
                          }
                        `,
                        tw`relative inline-block mt-8 p-4 rounded-xl overflow-hidden`
                      ]}
                    >
                      <div
                        css={[tw`relative flex items-center justify-between`]}
                      >
                        <Token css={[tw`w-6`]} />

                        <span
                          css={[tw`-mt-1 ml-6 font-body text-b1 md:text-b1-md`]}
                        >
                          {cost}
                        </span>
                      </div>
                    </button>
                  </>
                )}
              </Go>
            </>
          )}
        </div>
      </div>
    </article>
  );
};

export default ArticleCard;
